/* eslint-disable import/prefer-default-export */
import { find } from 'lodash';

export const planDetails = [
  {
    planId: 'basic60',
    monthlyPrice: '$20.00',
    quarterlyPrice: '$60.00',
  },
  {
    planId: 'basic75',
    monthlyPrice: '$25.00',
    quarterlyPrice: '$75.00',
  },
  {
    planId: 'pro105',
    monthlyPrice: '$35.00',
    quarterlyPrice: '$105.00',
  },
];

export const getPlanDetailsById = (planId) => find(planDetails, { planId });
