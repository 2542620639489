import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ThemeProvider from 'common/theme/ThemeProvider';

import EmbeddedUpdateCreditCard from 'modules/EmbeddedUpdateCreditCard';
import EmbeddedSubscribe from 'modules/EmbeddedSubscribe';

const App = () => {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path='/pay/embedded_subscribe/plan/:planId/nonce/:nonce'
            component={EmbeddedSubscribe}
          >
            <EmbeddedSubscribe />
          </Route>
          <Route exact path='/pay/embedded_update_card/nonce/:nonce'>
            <EmbeddedUpdateCreditCard isPastDue={false} />
          </Route>
          <Route exact path='/pay/embedded_update_card_past_due/nonce/:nonce'>
            <EmbeddedUpdateCreditCard isPastDue />
          </Route>
          <Route
            render={() => {
              window.location = `${window.PEP_CONFIG?.kBaseWebFEHost}${window.PEP_CONFIG?.kBaseUIURL}/index.html`;
            }}
          />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
