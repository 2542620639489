import { useCallback, useRef, useState } from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { getPlanDetailsById } from 'common/constants/planDetails';
import { isInIframe, postMessageToParent } from 'common/utils';
import { CircularProgressForButtons } from 'common/components/buttons';
import BtDropIn from 'common/BtDropIn';
import * as payApi from 'api/payment';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    maxWidth: theme.maxWidth,
    padding: theme.spacing(0, 0, 1),
    height: '100vh',
  },
  recurringContainer: {
    border: '1px solid #dcdcdc',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0, 2),
  },
  payButton: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
  },
}));

const EmbeddedSubscribe = () => {
  const classes = useStyles();
  const handlePaymentRef = useRef();
  const { planId, nonce } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const planDetails = getPlanDetailsById(planId);

  const onPaymentError = (error) => {
    postMessageToParent(
      {
        type: 'BT_ERROR',
        payload: JSON.stringify({ response: error.response }),
      },
      window.PEP_CONFIG?.kBaseWebFEHost
    );
    setSubmitting(false);
  };

  const onPaymentSuccess = () => {
    postMessageToParent({ type: 'BT_SUCCESS' }, window.PEP_CONFIG?.kBaseWebFEHost);
    setSubmitting(false);
  };

  const subscribePlan = (error, payload) => {
    if (error || !payload) {
      onPaymentError({ response: { data: { Message: error.message } } });
      setSubmitting(false);
    } else {
      payApi
        .subscribePlan({
          btNonce: payload.nonce,
          nonce,
          planId,
        })
        .then(onPaymentSuccess)
        .catch(onPaymentError);
    }
  };

  const handleSubmit = () => {
    if (handlePaymentRef.current) {
      postMessageToParent({ type: 'BT_SUBMITTING' }, window.PEP_CONFIG?.kBaseWebFEHost);
      setSubmitting(true);
      handlePaymentRef.current({ paymentCallback: subscribePlan });
    }
  };

  const setHandlePaymentCallback = useCallback((callback) => {
    handlePaymentRef.current = callback;
  }, []);

  const btOnLoad = useCallback(() => {
    postMessageToParent({ type: 'BT_LOADED' }, window.PEP_CONFIG?.kBaseWebFEHost);
  }, []);

  if (!isInIframe()) {
    // This module is meant to be opened in an iframe, if not, redirect the user
    window.location = `${window.PEP_CONFIG?.kBaseWebFEHost}${window.PEP_CONFIG?.kBaseUIURL}/index.html`;
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container direction='column'>
        <BtDropIn setHandlePaymentCallback={setHandlePaymentCallback} onLoad={btOnLoad} />
        <Grid item className={classes.recurringContainer}>
          <Typography>You will be charged {planDetails.quarterlyPrice} every 3 months.</Typography>
        </Grid>
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={submitting}
          className={classes.payButton}
          id='subscribe-pay-button'
        >
          Pay {planDetails.quarterlyPrice}
          <CircularProgressForButtons show={submitting} />
        </Button>
      </Grid>
    </div>
  );
};

export default EmbeddedSubscribe;
