import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';

import theme from './theme';

const ThemeProvider = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ThemeProvider;
