import { useEffect, useRef } from 'react';
import Braintree from 'braintree-web-drop-in';
import PropTypes from 'prop-types';

import * as payApi from 'api/payment';

const BtDropIn = ({ setHandlePaymentCallback, onLoad }) => {
  const dropInRef = useRef(); // Ref is needed, so react wont re-render 3rd party integration

  useEffect(() => {
    payApi.getBraintreeClientToken().then(({ data }) => {
      Braintree.create(
        {
          authorization: data,
          container: '#bt-dropin',
        },
        (err, instance) => {
          if (err) {
            return;
          }
          onLoad();
          setHandlePaymentCallback(({ paymentCallback }) => {
            instance.requestPaymentMethod(paymentCallback);
          });
        }
      );
    });
  }, [setHandlePaymentCallback, onLoad]);

  return <div id='bt-dropin' ref={dropInRef} />;
};

BtDropIn.propTypes = {
  setHandlePaymentCallback: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default BtDropIn;
