import { createMuiTheme } from '@material-ui/core/styles';
import { merge } from 'lodash';
import bsTheme from './bsTheme';
import swTheme from './swTheme';

const bootstrap = localStorage.getItem('bootstrap');

const theme = (config = {}) =>
  createMuiTheme(
    merge(
      {
        palette: {
          danger: 'red',
          pepGrey: '#CDD1DB',
        },
        overrides: {
          MuiScopedCssBaseline: {
            root: {
              backgroundColor: 'transparent',
            },
          },
          MuiButton: {
            label: {
              textTransform: 'none',
            },
          },
          MuiTab: {
            root: {
              textTransform: 'none',
            },
          },
          MuiDialogTitle: {
            root: {
              minWidth: 250,
            },
          },
          MuiInput: {
            formControl: {
              'label + &': {
                marginTop: '1.5rem',
              },
            },
          },
          MuiInputLabel: {
            root: {
              fontSize: '1.3em',
              color: 'black',
              '&:focus, &.Mui-error, .Mui-error &:focus, &.Mui-focused, ': {
                color: 'black',
              },
            },
            asterisk: {
              color: 'black',
              '&:focus, &.Mui-error, .Mui-error &:focus, &.Mui-focused, ': {
                color: 'black',
              },
            },
          },
          MuiIconButton: {
            root: {
              '&:focus': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            },
          },
          MuiFormLabel: {
            root: {
              fontSize: '0.9em',
              color: 'black',
              '&:focus, &.Mui-error, .Mui-error &:focus, &.Mui-focused, ': {
                color: 'black',
              },
            },
            asterisk: {
              color: 'black',
              '&:focus, &.Mui-error, .Mui-error &:focus, &.Mui-focused, ': {
                color: 'black',
              },
            },
          },
          MuiSelect: {
            icon: {
              paddingTop: 4,
            },
          },
        },
        maxWidth: 1600,
      },
      config
    )
  );

export default theme(bootstrap ? bsTheme : swTheme);
