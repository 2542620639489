/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export const updateCreditCard = ({ nonce, btNonce }) =>
  axios.post(
    `${window.PEP_CONFIG?.kBasePayBEHost}${window.PEP_CONFIG?.kBasePayBEUrl}/braintree/update_card`,
    {
      BraintreeNonce: btNonce,
      PPGNonce: nonce,
    }
  );

export const subscribePlan = ({ nonce, btNonce, planId }) =>
  axios.post(
    `${window.PEP_CONFIG?.kBasePayBEHost}${window.PEP_CONFIG?.kBasePayBEUrl}/braintree/subscribe`,
    {
      BraintreeNonce: btNonce,
      PPGNonce: nonce,
      BraintreePlanId: planId,
    }
  );

export const getBraintreeClientToken = () =>
  axios.get(
    `${window.PEP_CONFIG?.kBasePayBEHost}${window.PEP_CONFIG?.kBasePayBEUrl}/braintree/client_token`
  );
