import { fade } from '@material-ui/core';

const bsColors = {
  primary: '#007bff',
  secondary: '#f8f9fa',
  confirmation: '#51A351',
  confirmationHover: '#218838',
  danger: '#dc3545',
  primaryBorder: 'rgba(82,168,236,.8)',
};

const bsOptions = {
  palette: {
    primary: {
      main: bsColors.primary,
    },
    secondary: {
      main: bsColors.secondary,
    },
    danger: bsColors.danger,
    primaryBorder: bsColors.primaryBorder,
  },
  borderRadius: 4,
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'inherit',
      },
      root: {
        marginBottom: -5,
      },
    },
    MuiTab: {
      root: {
        fontWeight: 'bold',
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: bsColors.confirmation,
        '&:hover': { backgroundColor: bsColors.confirmationHover },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: `${bsColors.confirmationHover} !important`,
          },
        },
      },
    },
    MuiButtonGroup: {
      groupedContainedPrimary: {
        '&:not(:last-child)': {
          borderColor: 'white',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiInputBase: {
      root: {
        marginTop: 8,
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'white',
        border: '1px solid #ced4da',
        '&:focus, &:focus&:invalid': {
          borderColor: bsColors.primaryBorder,
          boxShadow: `${fade(bsColors.primaryBorder, 0.25)} 0 0 0.2rem 0.2rem`,
        },
        '.Mui-error &, .Mui-error &:invalid': {
          borderColor: `${bsColors.danger} !important`,
        },
        '.Mui-error &:focus, .Mui-error &:focus&:invalid': {
          borderColor: `${bsColors.danger} !important`,
          boxShadow: `${fade(bsColors.danger, 0.25)} 0 0 0.2rem 0.2rem`,
        },
      },
      input: {
        paddingLeft: 8,
      },
    },
    MuiDialogTitle: {
      root: {
        borderBottom: '1px solid lightgrey',
      },
    },
    MuiDialogActions: {
      root: {
        borderTop: '1px solid lightgrey',
        background: '#f5f5f5',
      },
    },
    MuiDialogContent: {
      root: {
        marginTop: 8,
      },
    },
    MuiSelect: {
      select: {
        borderRadius: 4,
        '&:focus': {
          borderRadius: 4,
        },
      },
    },
  },
};

export default bsOptions;
