/* eslint-disable import/prefer-default-export */

// if component is inside iframe, the window.self is not the same as window.top
// in some browsers, iframe cannot access window, therefore will throw an error
// so if it throws error, it's probably inside an iframe
export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const postMessageToParent = ({ type, payload }, target) =>
  window.parent.postMessage({ source: 'pep-payment-service', type, payload }, target);
