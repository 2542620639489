/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { CircularProgress, withStyles } from '@material-ui/core';

/* -------- CircularProgressForButtons -------- */
const CircularProgressWithStyle = withStyles(() => ({
  root: {
    position: 'absolute',
  },
}))(CircularProgress);

export const CircularProgressForButtons = ({ size, show }) =>
  show ? <CircularProgressWithStyle size={size} /> : null;

CircularProgressForButtons.propTypes = {
  size: PropTypes.number,
  show: PropTypes.bool.isRequired,
};

CircularProgressForButtons.defaultProps = {
  size: 20,
};
