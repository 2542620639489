const swColors = {
  primary: '#0069af',
  primaryDark: '#004a7c',
  secondary: '#D9DBD9',
  danger: '#dc3545',
  primaryBorder: '#0069af',
};

const swOptions = {
  palette: {
    primary: {
      main: swColors.primary,
    },
    secondary: {
      main: swColors.secondary,
    },
    danger: swColors.danger,
    primaryBorder: swColors.primaryBorder,
  },
  borderRadius: 0,
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: 'inherit',
      },
      root: {
        marginBottom: -5,
      },
    },
    MuiTab: {
      root: {
        fontWeight: 'bold',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      contained: {
        boxShadow: 'none',
      },
      containedSecondary: {
        '&:hover': { boxShadow: 'none' },
        '@media (hover: none)': {
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
      containedPrimary: {
        '&:hover': { backgroundColor: swColors.primaryDark, boxShadow: 'none' },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: `${swColors.primaryDark} !important`,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiButtonGroup: {
      contained: {
        boxShadow: 'none',
      },
      'groupedContainedSecondary, groupedContainedPrimary': {
        '&:not(:last-child)': {
          borderColor: 'white',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiInputBase: {
      root: {
        borderRadius: 0,
        position: 'relative',
        backgroundColor: 'white',
        border: '1px solid #ced4da',
        '&:focus, &:focus&:invalid': {
          borderColor: swColors.primaryBorder,
        },
        '&.Mui-error, &.Mui-error:invalid, &.Mui-error:focus&:invalid': {
          borderColor: swColors.danger,
        },
      },
      input: {
        paddingLeft: 8,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 0,
      },
    },
    MuiTooltip: {
      tooltip: { borderRadius: 0 },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 0,
      },
    },
  },
};

export default swOptions;
