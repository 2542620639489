import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { isInIframe, postMessageToParent } from 'common/utils';
import { CircularProgressForButtons } from 'common/components/buttons';
import BtDropIn from 'common/BtDropIn';
import * as payApi from 'api/payment';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    maxWidth: theme.maxWidth,
    padding: theme.spacing(0, 2, 1),
    height: '100vh',
  },
  updateButton: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  pastDueMessage: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

const EmbeddedUpdateCreditCard = ({ isPastDue }) => {
  const classes = useStyles();
  const handlePaymentRef = useRef();
  const { nonce } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const onPaymentError = (error) => {
    postMessageToParent(
      {
        type: 'BT_ERROR',
        payload: JSON.stringify({ response: error.response }),
      },
      window.PEP_CONFIG?.kBaseWebFEHost
    );
    setSubmitting(false);
  };

  const onPaymentSuccess = () => {
    postMessageToParent({ type: 'BT_SUCCESS' }, window.PEP_CONFIG?.kBaseWebFEHost);
    setSubmitting(false);
  };

  const updateCreditCard = (error, payload) => {
    if (error || !payload) {
      onPaymentError({ response: { data: { Message: error.message } } });
      setSubmitting(false);
    } else {
      payApi
        .updateCreditCard({
          btNonce: payload.nonce,
          nonce,
        })
        .then(onPaymentSuccess)
        .catch(onPaymentError);
    }
  };

  const handleSubmit = () => {
    if (handlePaymentRef.current) {
      postMessageToParent({ type: 'BT_SUBMITTING' }, window.PEP_CONFIG?.kBaseWebFEHost);
      setSubmitting(true);
      handlePaymentRef.current({ paymentCallback: updateCreditCard });
    }
  };

  const setHandlePaymentCallback = useCallback((callback) => {
    handlePaymentRef.current = callback;
  }, []);

  const btOnLoad = useCallback(() => {
    postMessageToParent({ type: 'BT_LOADED' }, window.PEP_CONFIG?.kBaseWebFEHost);
  }, []);

  if (!isInIframe()) {
    // This module is meant to be opened in an iframe, if not, redirect the user
    window.location = `${window.PEP_CONFIG?.kBaseWebFEHost}`;
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid container direction='column'>
        <BtDropIn setHandlePaymentCallback={setHandlePaymentCallback} onLoad={btOnLoad} />
        {isPastDue && (
          <Typography className={classes.pastDueMessage}>
            Updated card information will be applied to your past due balance
          </Typography>
        )}
        <Button
          color='primary'
          variant='contained'
          onClick={handleSubmit}
          disabled={submitting}
          className={classes.updateButton}
          id='update-card-button'
        >
          {isPastDue ? 'Update and Pay' : 'Update'}
          <CircularProgressForButtons show={submitting} />
        </Button>
      </Grid>
    </div>
  );
};

EmbeddedUpdateCreditCard.propTypes = {
  isPastDue: PropTypes.bool.isRequired,
};

export default EmbeddedUpdateCreditCard;
